import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

function ReviewsRedirect() {
  React.useEffect(() => {
    navigate('/#reviews');
  }, []);

  return null;
}

export default ReviewsRedirect;
